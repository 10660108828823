<template>
  <div>
    <b-row>
      <b-col md="3">
        <ContratoTomador :contratoId="contratoId" />
      </b-col>
      <b-col md="9">
        <ContratoDetalhes :contratoId="contratoId" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import ContratoTomador from './components/contratoAbrir/contratoTomador.vue'
  import ContratoDetalhes from './components/contratoAbrir/contratoDetalhes.vue'

  export default {
    components: {
      ContratoTomador,
      ContratoDetalhes,
    },
    data() {
      return {
        contratoId: null,
      }
    },
    created() {
      this.contratoId = this.$route.params.id
    },
  }
</script>
