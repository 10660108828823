import { formatarCPF } from '../Formatacao/Formatacao'

export const Ufs = [
  { text: 'Selecione', value: '' },
  { text: 'Acre', value: 'AC' },
  { text: 'Alagoas', value: 'AL' },
  { text: 'Amapá', value: 'AP' },
  { text: 'Amazonas', value: 'AM' },
  { text: 'Bahia', value: 'BA' },
  { text: 'Ceará', value: 'CE' },
  { text: 'Distrito Federal', value: 'DF' },
  { text: 'Espírito Santo', value: 'ES' },
  { text: 'Goiás', value: 'GO' },
  { text: 'Maranhão', value: 'MA' },
  { text: 'Mato Grosso', value: 'MT' },
  { text: 'Mato Grosso do Sul', value: 'MS' },
  { text: 'Minas Gerais', value: 'MG' },
  { text: 'Pará', value: 'PA' },
  { text: 'Paraíba', value: 'PB' },
  { text: 'Paraná', value: 'PR' },
  { text: 'Pernambuco', value: 'PE' },
  { text: 'Piauí', value: 'PI' },
  { text: 'Rio de Janeiro', value: 'RJ' },
  { text: 'Rio Grande do Norte', value: 'RN' },
  { text: 'Rio Grande do Sul', value: 'RS' },
  { text: 'Rondônia', value: 'RO' },
  { text: 'Roraima', value: 'RR' },
  { text: 'Santa Catarina', value: 'SC' },
  { text: 'São Paulo', value: 'SP' },
  { text: 'Sergipe', value: 'SE' },
  { text: 'Tocantins', value: 'TO' },
]

/**
 * Detecta o navegador do usuário com base no `userAgent`.
 *
 * @returns {string} O nome do navegador detectado ou "Desconhecido" se não for possível identificar.
 */
export function detectarNavegador(): string {
  const agenteUsuario = navigator.userAgent

  if (agenteUsuario.includes('Edg')) {
    return 'Microsoft Edge'
  } else if (agenteUsuario.includes('Chrome') && agenteUsuario.includes('Safari')) {
    return 'Google Chrome'
  } else if (agenteUsuario.includes('Safari') && !agenteUsuario.includes('Chrome')) {
    return 'Safari'
  } else if (agenteUsuario.includes('Firefox')) {
    return 'Firefox'
  } else if (agenteUsuario.includes('MSIE') || agenteUsuario.includes('Trident')) {
    return 'Internet Explorer'
  } else {
    return 'Desconhecido'
  }
}

export function censurarCPF(cpf) {
  const formatado = formatarCPF(cpf)
  return '***' + formatado.substring(3, cpf.length - 2) + '**'
}

/**
 * Converte um texto em PascalCase, onde a primeira letra de cada palavra é maiúscula
 * e as palavras são separadas por espaços.
 *
 * @param {string} texto - O texto a ser convertido.
 * @returns {string} O texto convertido em PascalCase com espaços.
 */
export function PascalCaseComEspaco(texto: string): string {
  if (typeof texto !== 'string') {
    throw new Error(
      `A função PascalCaseComEspaco recebe um parâmetro do tipo string, mas o parâmetro recebido foi do tipo ${typeof texto}.`,
    )
  }

  // Divide o texto em palavras usando espaço como separador
  const palavras = texto.split(' ')

  // Capitaliza a primeira letra de cada palavra e junta novamente as palavras
  const resultado = palavras
    .map((palavra) => {
      // Verifica se a palavra está em letras minúsculas
      if (palavra.length > 0 && typeof palavra === 'string') {
        return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase()
      } else {
        throw new Error(`A palavra ${palavra} não pode ser vazia ou não é do tipo string.`)
      }
    })
    .join(' ')

  return resultado
}

/**
 * Remove acentuações de uma string.
 *
 * @param {string} texto - A string da qual deseja remover os acentos.
 * @returns {string} Uma nova string com os acentos removidos.
 *
 * @example
 * const textoComAcentos = "Coração café açúcar";
 * const textoSemAcentos = removerAcentos(textoComAcentos);
 */
export function removerAcentos(texto) {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * Arrendonda um número para cima, considerando apenas os dois casas decimais.
 *
 * @param {number} num - O número a ser arrendondado.
 * @returns {number} O número arrendondado para dois casas decimais.
 *
 * @example
 * const numero = 10.123456
 * const numeroArrendondado = roundUp(numero)
 */
export function roundUp(num: number): number {
  if (typeof num !== 'number') {
    throw new Error('O parâmetro deve ser um número')
  }

  const DECIMAL_SHIFT = 100
  const DECIMAL_PLACES = 2

  const rounded = Math.ceil(num * DECIMAL_SHIFT) / DECIMAL_SHIFT
  return parseFloat(rounded.toFixed(DECIMAL_PLACES))
}

/**
 * Arredonda o número fornecido com base na terceira casa decimal.
 * Se a terceira casa decimal for maior que 0, a segunda casa decimal será arredondada para cima.
 *
 * @param {number} valor - O valor decimal a ser arredondado, assumindo até quatro casas decimais.
 * @returns {number} O número arredondado com base na condição especificada.
 * @throws {Error} Lança um erro se o número fornecido tiver mais de quatro casas decimais.
 */
export function arrendondarCalculo(valor: number): number {
  const valorString = valor.toFixed(4) // Assegura que o número é tratado como uma string com até quatro casas decimais
  const [parteInteira, parteDecimal] = valorString.split('.')

  if (!parteDecimal) {
    return valor // Retorna o valor original se não houver casas decimais
  }

  if (parteDecimal.length > 4) {
    throw new Error('O valor fornecido tem mais de quatro casas decimais.')
  }

  const terceiraCasaDecimal = parseInt(parteDecimal[2], 10)

  if (terceiraCasaDecimal > 0) {
    // Se a terceira casa decimal é maior que zero, arredonda a segunda casa para cima
    const valorAjustado = parseFloat(parteInteira + '.' + parteDecimal.substring(0, 2)) + 0.01
    return parseFloat(valorAjustado.toFixed(2))
  }

  return parseFloat(valor.toFixed(2)) // Arredonda para duas casas decimais se a terceira casa não influencia
}

/**
 * Calcula a idade com base na data de nascimento fornecida.
 * @param {string} dataNascimento - A data de nascimento no formato 'YYYY-MM-DD'.
 * @returns {number} A idade calculada.
 * @throws {Error} Lanca um erro se a data de nascimento fornecida for invalida.
 */
export function calcularIdade(dataNascimento: string): number {
  const dataAtual = new Date()
  const dataNascimentoFormatada = new Date(dataNascimento)

  if (
    isNaN(dataNascimentoFormatada.getTime()) ||
    dataNascimentoFormatada.getFullYear() > dataAtual.getFullYear() ||
    (dataNascimentoFormatada.getFullYear() === dataAtual.getFullYear() &&
      (dataNascimentoFormatada.getMonth() > dataAtual.getMonth() ||
        (dataNascimentoFormatada.getMonth() === dataAtual.getMonth() &&
          dataNascimentoFormatada.getDate() > dataAtual.getDate())))
  ) {
    throw new Error('A data de nascimento fornecida é inválida.')
  }

  let idade = dataAtual.getFullYear() - dataNascimentoFormatada.getFullYear()

  // Verifica se a data de anivers rio j  ocorreu no ano atual
  const mesAtual = dataAtual.getMonth()
  const diaAtual = dataAtual.getDate()
  const mesNascimento = dataNascimentoFormatada.getMonth()
  const diaNascimento = dataNascimentoFormatada.getDate()

  if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
    idade--
  }

  return idade
}
