<template>
  <div>
    <b-card class="text-center p-2">
      <div>
        <b-avatar variant="secondary" size="13rem" class="mb-1" :src="tomador.foto" />
        <h3 class="text-primary">{{ tomador.nomeCompleto }}</h3>
        <div>
          <h4 class="">{{ formatarSexo(tomador.sexo) }} - {{ idadeTomador }} anos</h4>
        </div>
        <hr width="80%" class="mx-auto" />
      </div>
      <div>
        <p class="text-lg">CPF</p>
        <h4>{{ tomador.cpf }}</h4>
        <p>Matricula</p>
        <h4>{{ tomador.matricula }}</h4>
        <p>Categoria</p>
        <h4>{{ tomador.situacaoPrevidenciaria }}</h4>
        <p>Data de Nascimento</p>
        <h4>{{ formatarDataCurto(tomador.dataNascimento) }}</h4>
      </div>
    </b-card>
  </div>
</template>
<script>
  import {
    formatarSexo,
    formatarInativoAposentado,
    formatarDataCurto,
    capitalizeText,
  } from '@/utils/Functions/Formatacao/Formatacao'
  import { calcularIdade } from '@/utils/Functions/Genericas/Genericas'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      formatarSexo,
      formatarInativoAposentado,
      formatarDataCurto,
      capitalizeText,
      calcularIdade,
    },
    props: {
      contratoId: {},
    },
    data() {
      return {
        formatarSexo,
        calcularIdade,
        formatarInativoAposentado,
        capitalizeText,
        formatarDataCurto,
        idadeTomador: 0,
        tomador: {},
      }
    },
    async mounted() {
      await this.buscarTomador()
    },
    methods: {
      async calcularIdadeTomador() {
        this.idadeTomador = await calcularIdade(this.tomador.dataNascimento)
      },

      async buscarTomador() {
        useJwt
          .get(`cadastro/tomador/buscarTomadorPorContrato/${this.contratoId}`)
          .then((response) => {
            this.tomador = response.data
            this.tomador = {
              ...this.tomador,
              nomeCompleto: capitalizeText(this.tomador.nomeCompleto),
              situacaoPrevidenciaria: formatarInativoAposentado(this.tomador.situacaoPrevidenciaria),
              sexo: formatarSexo(this.tomador.sexo),
            }
            this.calcularIdadeTomador()
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>
