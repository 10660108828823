<template>
  <div>
    <b-row>
      <b-col cols="12" class="d-flex align-items-center justify-content-center mt-2">
        <b-card class="border d-flex p-1">
          <b-row class="justify-content-between">
            <b-col cols="2" class="d-flex align-items-center justify-content-center text-center">
              <div class="p-1 mr-1 rounded-pill bg-dark"></div>
              <p class="m-auto">Outros</p>
            </b-col>
            <b-col cols="2" class="d-flex align-items-center justify-content-center text-center">
              <div class="p-1 mr-1 rounded-pill bg-warning"></div>
              <p class="m-auto">Pendente</p>
            </b-col>
            <b-col cols="2" class="d-flex align-items-center justify-content-center text-center">
              <div class="p-1 mr-1 rounded-pill bg-success"></div>
              <p class="m-auto">Consolidado</p>
            </b-col>
            <b-col cols="2" class="d-flex align-items-center justify-content-center text-center">
              <div class="p-1 mr-1 rounded-pill bg-info"></div>
              <p class="m-auto">Quitado</p>
            </b-col>
            <b-col cols="2" class="d-flex align-items-center justify-content-center text-center">
              <div class="p-1 mr-1 rounded-pill bg-danger"></div>
              <p class="m-auto">Cancelado</p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="timeline w-100 ml-0">
      <b-col cols="12" v-if="timelineEvents.length">
        <div v-for="(item, index) in timelineEvents" :key="item.id" class="timeline-item ml-0 pl-0 border-0">
          <b-row class="timeline-row py-2">
            <!-- Linha vertical no meio -->
            <div :class="['timeline-line', { 'last-item': index === timelineEvents.length - 1 }]"></div>

            <!-- Bola de marcação -->
            <div :class="['timeline-marker', { 'last-item': index === timelineEvents.length - 1 }]"></div>

            <!-- Coluna da esquerda -->
            <b-col v-if="index % 2 !== 0" class="timeline-date d-flex justify-content-end mr-4 align-items-center">
              <p class="pb-0 mb-0 text-center justify-content-center">{{ formatarDataHora(item.dataDaInteracao) }}</p>
            </b-col>

            <!-- Card -->
            <b-col>
              <b-card
                header-text-variant="white"
                :header-bg-variant="`${item.color}`"
                :border-variant="`${item.color}`"
                class="mb-1"
              >
                <template #header>
                  <h5 class="mb-0 text-white">
                    {{
                      tiposStatusGenerico[item.statusGenerico] == 'Criação'
                        ? item.retorno.statusIntegracao
                        : tiposStatusGenerico[item.statusGenerico]
                    }}
                  </h5>
                  <span
                    v-b-tooltip.hover
                    title="Visualizar"
                    class="justify-content-center align-items-center my-0 ml-1 p-0"
                    @click="visualizarDetalhes(item)"
                    v-if="item.statusGenerico !== 'criacao'"
                  >
                    <feather-icon icon="SearchIcon" size="16" class="cursor-pointer" />
                  </span>
                </template>
                <b-card-body class="p-0">
                  <b-row class="px-1 pt-1" v-if="item.statusGenerico !== 'criacao'">
                    <p class="mb-0" v-if="item.retorno.statusIntegracao">{{ item.retorno.statusIntegracao }}</p>
                  </b-row>
                  <b-row class="px-1 pt-1" v-if="item.User">
                    <p class="mb-0">Responsável: {{ item.User.name }}</p>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- Coluna da direita -->
            <b-col v-if="index % 2 === 0" class="timeline-date d-flex justify-content-start ml-4 align-items-center">
              <p class="pb-0 mb-0 text-center justify-content-center">{{ formatarDataHora(item.dataDaInteracao) }}</p>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <b-modal id="modalLinhaTempo" ref="modalLinhaTempo" size="md" centered hide-footer hide-header scrollable>
      <ModalLinhaTempo @fecharModal="fecharModal" :itemParaModal="itemParaModal" :tiposStatusGenerico="tiposStatusGenerico" />
    </b-modal>
  </div>
</template>

<script>
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
  import ModalLinhaTempo from './contratoDetalhesModalLinhaTempo.vue'
  import { formatarDataHora } from '@/utils/Functions/Formatacao/Formatacao'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      FeatherIcon,
      ModalLinhaTempo,
      formatarDataHora,
    },
    props: {
      contratoId: {},
    },
    data() {
      return {
        timelineEvents: [],
        itemParaModal: {},
        formatarDataHora,
        tiposStatusGenerico: {
          criacao: 'Criação',
          contaBancariaSolicitada: 'Conta Bancária Solicitada',
          contaBancariaCriada: 'Conta Bancária Criada',
          contaBancariaNegada: 'Conta Bancária Negada',
          erroAberturaContaBancaria: 'Erro na Abertura da Conta Bancária',
          propostaEnviadaParaOBanco: 'Proposta Enviada para o Banco',
          erroAoEnviarPropostaParaOBanco: 'Erro ao Enviar Proposta para o Banco',
          contratoEmAndamento: 'Contrato em Andamento',
          gerandoCCB: 'Gerando CCB',
          ccbNegada: 'CCB Negada',
          ccbEnviadaAoTomador: 'CCB Enviada ao Tomador',
          ccbAguardandoAssinatura: 'CCB Aguardando Assinatura',
          ccbAssinada: 'CCB Assinada',
          ccbExpirada: 'CCB Expirada',
          aguardandoAverbacao: 'Aguardando Averbação',
          creditoDisponivel: 'Crédito Disponível',
          desembolsoEfetuado: 'Desembolso Efetuado',
          parcelaEmDia: 'Parcela em Dia',
          parcelaEmAtraso: 'Parcela em Atraso',
        },
      }
    },
    async mounted() {
      await this.carregarHistoricoLinhaTempo()
    },
    methods: {
      async corPorStatus() {
        this.timelineEvents.forEach((event) => {
          switch (event.tipoStatusLinhaTempo) {
            case 'Pendente':
              event.color = 'warning'
              break
            case 'Consolidado':
              event.color = 'success'
              break
            case 'Cancelado':
              event.color = 'danger'
              break
            default:
              event.color = 'dark'
              break
          }
        })
      },
      visualizarDetalhes(item) {
        this.itemParaModal = item
        this.$refs['modalLinhaTempo'].show()
      },
      async carregarHistoricoLinhaTempo() {
        useJwt
          .get(`linhaTempoContrato/${this.contratoId}`)
          .then(async (response) => {
            const dados = response.data
            const dadosAaa = dados.map((item) => {
              // Verificar se item.retorno não é nulo e é uma string válida para JSON.parse
              let retornoObj = {}
              if (item.retorno) {
                try {
                  retornoObj = JSON.parse(item.retorno)
                } catch (error) {
                  console.error('Erro ao fazer o parse de retorno:', error)
                  retornoObj = {} // Em caso de erro, define um objeto vazio para evitar falhas
                }
              }

              // Transformando o campo 'anexos' em um array de nomes, se existir em retornoObj
              const anexos = retornoObj.anexos ? retornoObj.anexos.map((anexo) => anexo.nome) : []

              return {
                ...item,
                retorno: {
                  ...retornoObj,
                  anexos,
                },
              }
            })

            this.timelineEvents = dadosAaa
            await this.corPorStatus()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      fecharModal() {
        this.$refs['modalLinhaTempo'].hide()
      },
    },
  }
</script>

<style>
  .timeline {
    position: relative;
  }

  .timeline-item {
    position: relative;
    padding-bottom: 0px !important;
  }

  .timeline-row {
    display: flex;
    align-items: center;
    position: relative;
  }

  .timeline-line {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    width: 0.5vw;
    height: 100%;
    background-color: #016b3a;
    z-index: 0;
  }

  .timeline-line.last-item {
    height: 50%;
  }

  .timeline-marker {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2vw;
    height: 2vh;
    padding: 0.4vw;
    background-color: #016b3a;
    border-radius: 50%;
    z-index: 1;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 10px solid #016b3a;
  }

  .timeline-item .b-card {
    z-index: 2;
  }

  .timeline-date p {
    text-align: center;
  }
</style>
