<template>
  <b-card :header-bg-variant="corStatus">
    <template #header>
      <h4 class="mb-0 justify-content-start text-white">Contrato | {{ contrato.codigo }}</h4>
      <b-badge id="badgeStatus" class="rounded-pill mb-0 px-2 py-50 justify-content-end" :class="`alert-${corStatus}`">
        {{ contrato.status }}
      </b-badge>
    </template>
    <div class="d-flex mt-4">
      <b-tabs class="w-100 px-4 tabs-centered">
        <b-tab active>
          <template #title>
            <div class="text-center font-weight-bold">Informações</div>
          </template>
          <ContratoDetalhesInformacao :contrato="contrato" :contratoId="contratoId" />
        </b-tab>
        <b-tab>
          <template #title>
            <div class="text-center font-weight-bold">Anexos</div>
          </template>
          <ContratoDetalhesAnexos :contratoId="contratoId" />
        </b-tab>
        <b-tab>
          <template #title>
            <div class="text-center font-weight-bold">Linha do tempo</div>
          </template>
          <ContratoDetalhesLinhaTempo :contratoId="contratoId" />
        </b-tab>
      </b-tabs>
    </div>
  </b-card>
</template>
<script>
  import ContratoDetalhesInformacao from './contratoDetalhesInformacao.vue'
  import ContratoDetalhesAnexos from './contratoDetalhesAnexos.vue'
  import ContratoDetalhesLinhaTempo from './contratoDetalhesLinhaTempo.vue'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      ContratoDetalhesInformacao,
      ContratoDetalhesAnexos,
      ContratoDetalhesLinhaTempo,
    },
    props: {
      contratoId: {},
    },
    data() {
      return {
        corStatus: '',
        contrato: {},
      }
    },
    async created() {
      await this.buscarDadosContratos()
    },
    methods: {
      corPorStatus() {
        switch (this.contrato.status) {
          case 'Pendente':
            this.corStatus = 'warning'
            break
          case 'Consolidado':
            this.corStatus = 'success'
            break
          case 'Cancelado':
            this.corStatus = 'danger'
            break
          default:
            this.corStatus = 'info'
            break
        }
      },

      async buscarDadosContratos() {
        useJwt
          .get(`financeiro/DadosContrato/${this.contratoId}`)
          .then((response) => {
            this.contrato = response.data
            this.corPorStatus()
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style>
  .tabs-centered .nav-tabs {
    justify-content: center;
  }
  #badgeStatus.alert-success {
    background-color: #c2f5ec !important;
  }
  #badgeStatus.alert-info {
    background-color: #dae8f8 !important;
  }
  #badgeStatus.alert-danger {
    background-color: #f3c3c4 !important;
  }
  #badgeStatus.alert-warning {
    background-color: #fcebde !important;
  }
</style>
