<template>
  <div class="mt-4">
    <b-row md="12" class="justify-content-center">
      <b-col v-for="(item, index) in cardContrato" :key="index" cols="6" md="3" lg="3">
        <b-card class="border" v-if="!isBusy">
          <h4 class="font-weight-bolder">{{ item.value }}</h4>
          <p>{{ item.title }}</p>
        </b-card>
        <b-card class="border" v-else>
          <b-skeleton class="custom-padding-margin" width="50%" height="70%" />
          <b-skeleton class="custom-padding-margin" width="70%" />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="!isBusy">
      <b-table
        v-if="totalRows > 0"
        :items="itemsParcelas"
        :fields="fieldsParcelas"
        ref="tabelaParcelas"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :sort-by.sync="sortBy"
        :sort-direction="sortDirection"
      >
        <template #cell(pagamento)="row">
          <b-badge pills class="alert alert-primary px-1 py-50 m-0 rounded-pill" v-if="row.item.pagamento"> Pago </b-badge>
          <b-badge pills class="alert alert-warning px-1 py-50 m-0 rounded-pill" v-else> Pendente </b-badge>
        </template>
      </b-table>
      <b-card-body v-if="totalRows > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          style="align-items: center"
          label="Por Página"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>
        <!-- pagination -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
    </b-row>
    <b-skeleton-table v-else :rows="4" no-animated />
  </div>
</template>
<script>
  import { formatarValor, formatarValorPorcentagem, formatarDataCurto } from '@/utils/Functions/Formatacao/Formatacao'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      formatarValor,
      formatarValorPorcentagem,
      formatarDataCurto,
      useJwt,
    },
    props: {
      contrato: {
        type: Object,
        default: () => {},
      },
      contratoId: {},
    },
    data() {
      return {
        isBusy: true,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        sortBy: 'parcela',
        sortDirection: 'asc',
        cardContrato: [],
        itemsParcelas: [],
        fieldsParcelas: [
          {
            key: 'parcela',
            label: 'Parcela',
          },
          {
            key: 'valor',
            label: 'Valor',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'amortizacao',
            label: 'Amortização',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'taxa',
            label: 'Taxa',
            formatter: (value) => (value ? formatarValorPorcentagem(value) : formatarValorPorcentagem(0)),
          },
          {
            key: 'juros',
            label: 'Juros',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'vencimento',
            label: 'Vencimento',
            formatter: (value) => formatarDataCurto(value),
          },
          {
            key: 'pagamento',
            label: 'Pagamento',
          },
        ],
      }
    },
    async mounted() {
      await this.popularTabelaParcelas()
    },
    methods: {
      async popularCardContrato() {
        this.cardContrato = [
          {
            title: 'Valor Emprestado',
            value: formatarValor(this.contrato.saldoDevedor),
          },
          {
            title: 'Saldo Devedor Total',
            value: formatarValor(this.contrato.totalEmprestado),
          },
          {
            title: 'Total com Juros',
            value: formatarValor(this.contrato.totalComJuros),
          },
          {
            title: 'Nº de Parcelas',
            value: `${this.totalParcelasPagas} de ${this.contrato.numeroParcelas}`,
          },
          {
            title: 'Valor da Parcela',
            value: formatarValor(this.contrato.valorParcela),
          },
          {
            title: 'Taxa',
            value: this.contrato.taxaEmprestimo ? `${this.contrato.taxaEmprestimo}%` : '0%',
          },
          {
            title: 'Data de Inicio',
            value: formatarDataCurto(this.contrato.createdAt),
          },
          {
            title: 'Origem',
            value: this.contrato.origem,
          },
        ]
      },

      async popularTabelaParcelas() {
        this.isBusy = true
        useJwt
          .get(`financeiro/DadosContratoParcelas/${this.contratoId}`)
          .then(async (response) => {
            this.itemsParcelas = response.data.parcelas
            this.totalParcelasPagas = response.data.totalParcelasPagas
            this.totalRows = this.itemsParcelas.length
            await this.popularCardContrato()
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
    },
  }
</script>
