<template>
  <div class="mt-4">
    <div id="anexoDocumentos">
      <vue2Dropzone
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-file-added="onFileAdded"
        :useCustomSlot="true"
        class="custom-dropzone"
        v-show="!isBusy"
      >
        <feather-icon icon="UploadCloudIcon" class="align-self-center alert alert-primary p-2 rounded-circle mb-1" size="100" />
        <h4 class="text-primary px-1 mb-0">Arraste e solte o arquivo aqui ou <b>Clique aqui para escolher</b></h4>
        <p class="text-center">
          Você pode adicionar anexos que não estão necessariamente incluídos na lista de anexos obrigatórios
        </p>
      </vue2Dropzone>
      <div v-if="isBusy">
        <b-skeleton width="100%" height="250px" class="mb-2" animation="fade" />
      </div>
    </div>
    <div v-if="arquivoSelecionado && !isBusy" class="mt-2 align-items-center justify-content-center">
      <b-row md="12" class="justify-content-center">
        <b-col cols="4">
          <b-form-group label="Descrição do Arquivo">
            <b-form-input v-model="descricaoArquivo" placeholder="Adicione uma descrição"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row md="12" class="justify-content-center">
        <b-col cols="2" class="d-flex justify-content-start">
          <b-button variant="outline-secondary" class="w-100" @click="limparArquivo">Limpar</b-button>
        </b-col>
        <b-col cols="2" class="d-flex justify-content-end">
          <b-button variant="success" class="w-100" @click="confirmarAnexo">Confirmar</b-button>
        </b-col>
      </b-row>
    </div>

    <div class="mt-4" v-if="cardAnexos.length > 0 && !isBusy">
      <h2>Lista de Anexos</h2>
      <p class="mb-0">Aqui você encontra a lista de anexos necessários para este contrato</p>
      <div class="mt-2">
        <b-card v-for="(item, index) in cardAnexos" class="border" :key="index">
          <div class="d-flex align-items-center align-self-center">
            <div class="justify-content-start d-flex align-items-center w-100">
              <FeatherIcon icon="FileTextIcon" class="align-self-center text-primary mb-0 p-1" size="65" />
              <div>
                <h4 class="text-primary px-1 mb-0">{{ item.descricao }}</h4>
                <p class="px-1 mb-0 cursor-pointer outline" @click="baixaAnexo(item.id)">{{ item.nomeArquivo }}</p>
              </div>
            </div>
            <div class="justify-content-end">
              <b-button variant="danger" pill @click="removerArquivo(item.id)">Remover</b-button>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div v-else-if="isBusy">
      <b-skeleton width="100%" height="15vh" class="mb-2 mt-2" animation="fade" />
    </div>
  </div>
</template>

<script>
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber, normalizeFileName } from '@/utils/Functions/Formatacao/Formatacao'

  export default {
    components: {
      vue2Dropzone,
    },
    props: {
      contratoId: {},
    },
    data() {
      return {
        isBusy: false,
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          maxFilesize: 20,
          maxFiles: 1,
          autoProcessQueue: false, // Desabilita o envio automático
        },
        arquivoSelecionado: null,
        descricaoArquivo: '',
        cardAnexos: [],
      }
    },
    async mounted() {
      await this.carregarAnexos()
    },
    methods: {
      onFileAdded(file) {
        this.arquivoSelecionado = file
      },
      limparArquivo() {
        this.$refs.myVueDropzone.removeAllFiles()
        this.arquivoSelecionado = null
        this.descricaoArquivo = ''
      },
      async confirmarAnexo() {
        this.isBusy = true
        if (!this.arquivoSelecionado || !this.descricaoArquivo) {
          this.$message.error('Por favor, selecione um arquivo e adicione uma descrição.')
          this.isBusy = false
          return
        }

        const formData = new FormData()
        formData.append('modulo', 'financeiro')
        formData.append('nomeArquivo', normalizeFileName(this.arquivoSelecionado.name))
        formData.append('descricao', this.descricaoArquivo)
        formData.append('sigla', 'AgendaBank')
        formData.append('file', this.arquivoSelecionado)
        formData.append('financeiroId', formatarParaNumber(this.contratoId))

        await useJwt
          .postDocumento('/aws/uploadArquivoAWS', () => {}, formData)
          .then((response) => {
            this.$message.success('Arquivo anexado com sucesso!')
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao anexar o arquivo!')
          })
          .finally(async () => {
            this.isBusy = false
            this.limparArquivo()
            await this.carregarAnexos()
          })
      },

      removerArquivo(id) {
        this.isBusy = true

        useJwt
          .delete('aws/deleteArquivoAws', id)
          .then((response) => {
            this.$message.success('Arquivo removido com sucesso!')
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao remover o arquivo!')
          })
          .finally(() => {
            this.isBusy = false
            this.carregarAnexos()
          })
      },

      async carregarAnexos() {
        this.isBusy = true
        const params = {
          id: formatarParaNumber(this.contratoId),
          modulo: 'financeiro',
        }
        useJwt
          .post('aws/GetBuscarArquivoAws', params)
          .then((response) => {
            const dados = response.data
            this.cardAnexos = dados.map((item) => {
              return {
                id: item.id,
                nomeArquivo: item.nomeArquivo,
                descricao: item.descricao,
              }
            })
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },

      async baixaAnexo(item) {
        useJwt
          .get(`aws/downladArquivoAws/${item}`)
          .then((response) => {
            const link = response.data
            window.open(link.s3Url, '_blank')
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style>
  .custom-dropzone {
    width: 100% !important;
    height: 75% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    margin: 0px !important;
    border: 2px dashed #006d3f !important;
  }
  .dz-progress {
    display: none !important;
  }

  .dz-preview .dz-image-preview {
    width: max-content !important;
  }
</style>
